import React, { useContext } from 'react';
import _ from 'lodash';

import type { ConfigContextType } from '../../../context/config.context';
import { ConfigContext } from '../../../context/config.context.ts';

import type { BodySliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/BodySlice/BodySlice';
import { BodySlice } from '@ww-digital/web-palette-react/dist/components/Slice/BodySlice/BodySlice';

interface BodySliceContainerProps {
  slice: BodySliceProps;
}

export const BodySliceContainer = ({
  slice,
}: BodySliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);
  const { translations } = useContext<ConfigContextType>(ConfigContext);

  const processJSONKey = translations.PROCESS_JSON === 'true';

  sliceView.processJSON = processJSONKey;

  return <BodySlice {...sliceView} />;
};
