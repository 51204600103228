import React, { useContext } from 'react';
import _ from 'lodash';

import type { ToutSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/ToutSlice/ToutSlice';
import type { MarketContextType } from '../../../context/market.context';
import type { ConfigContextType } from '../../../context/config.context';
import { ConfigContext } from '../../../context/config.context.ts';

import { ToutSlice } from '@ww-digital/web-palette-react/dist/components/Slice/ToutSlice/ToutSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';
import { MarketContext } from '../../../context/market.context.ts';

interface ToutSliceContainerProps {
  daCategory: string;
  slice: ToutSliceProps;
}

export const ToutSliceContainer = ({
  daCategory,
  slice,
}: ToutSliceContainerProps): JSX.Element => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const sliceView = _.cloneDeep(slice);
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const processJSONKey = translations.PROCESS_JSON === 'true';

  // Analytics tracking for tout CTAs.
  const daAction = AnalyticsUtility.formatAction(
    'tout_cta',
    slice.tout.button.text,
  );
  const daLabel = AnalyticsUtility.formatLabel([
    slice.tout.heading.text,
    slice.tout.bodyText,
  ]);

  sliceView.tout.processJSON = processJSONKey;

  sliceView.tout.button.attributes = {};
  sliceView.tout.button.attributes['da-category'] = daCategory;
  sliceView.tout.button.attributes['da-action'] = daAction;
  sliceView.tout.button.attributes['da-label'] = daLabel;

  if (slice.tout.secondButton) {
    const daAction2 = AnalyticsUtility.formatAction(
      'tout_second_cta',
      slice.tout.secondButton.text,
    );

    sliceView.tout.secondButton.attributes = {};
    sliceView.tout.secondButton.attributes['da-category'] = daCategory;
    sliceView.tout.secondButton.attributes['da-action'] = daAction2;
    sliceView.tout.secondButton.attributes['da-label'] = daLabel;
  }

  // If Pricing Enabled, use standard recurly checkout URL.
  if (sliceView.tout.button && sliceView.tout.pricing) {
    sliceView.tout.button.url = PricingUtility.getRecurlyPricingUrl(
      marketContext.country,
      marketContext.language,
    );
  }

  return <ToutSlice {...sliceView} />;
};
